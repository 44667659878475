<template>
  <v-container v-if="user.loggedIn">
    <v-row justify="center" class="text-center">
      <v-col cols="12" md="10" lg="9">
        <v-row justify="center">
          <v-col cols="12" md="10" lg="9">
            <v-row no-gutters>
              <v-btn icon large color="primary" class="mr-4" exact to="/eltern/mein-profil">
                <font-awesome-icon icon="fa-light fa-arrow-left" size="lg" />
              </v-btn>
              <h1 class="primary--text font-weight-light"><font-awesome-icon icon="fa-light fa-children" class="mr-4" />Meine Kinder</h1>
            </v-row>
          </v-col>
          <v-col cols="12" md="10" lg="9">
            <v-divider class="mb-5"></v-divider>
          </v-col>
          <v-col cols="12" md="10" lg="9" class="my-3" v-for="(kind, index) in user.data.kinder" :key="'Kind'+index">
            <v-card color="secondary" class="rounded-xl" @click="edit(index)">
              <v-row justify="center">
                <v-col cols="auto">
                  <h1>
                    <font-awesome-icon v-if="kind.geschlecht == 'Männlich'" icon="fa-light fa-child" />
                    <font-awesome-icon v-else-if="kind.geschlecht == 'Weiblich'" icon="fa-light fa-child-dress" />
                    <font-awesome-icon v-else icon="fa-light fa-child-reaching" />
                  </h1>
                </v-col>
                <v-col cols="auto" align-self="center">
                  <h2 class="font-weight-light"><b>{{kind.vorname}} {{kind.nachname}}</b> ({{kind.alter}})</h2>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" md="10" lg="9" class="my-3">
            <v-btn rounded x-large color="success" @click="addChild = true">
              <font-awesome-icon icon="fa-regular fa-plus" class="mr-2" />Kind hinzufügen
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" v-model="addChild" :content-class="$vuetify.breakpoint.smAndDown ? '' : 'rounded-xl'">
      <v-card color="#CFD8DC" :class="$vuetify.breakpoint.smAndDown ? 'rounded-0' : 'rounded-xl'">
        <v-toolbar color="primary" dark>
          <v-toolbar-title>Kind bearbeiten</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="addChild = false">
            <font-awesome-icon icon="fa-light fa-xmark" size="lg" />
          </v-btn>
        </v-toolbar>
        <v-row class="pa-4">
          <v-col cols="12" md="6">
            <v-select :items="['Männlich', 'Weiblich', 'Keine Angabe']" hide-details="auto" rounded filled v-model="child.geschlecht" label="Geschlecht"></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field rounded filled hide-details="auto" v-model="child.vorname" label="Vorname"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field rounded filled hide-details="auto" v-model="child.nachname" label="Nachname"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field filled rounded hide-details="auto" label="Geburtsdatum" v-model="child.geburtsdatum" :rules="[rules.required, rules.geburtsdatum, custom_rules.alter]" />
          </v-col>
          <v-col cols="12" md="6">
            <v-select v-model="child.sportarten" :rules="[rules.required, custom_rules.sportarten]" hide-details="auto"  multiple :items="sports" item-value="name" item-text="name" label="Sportarten" rounded filled></v-select>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn rounded x-large :disabled="!valid" color="success" @click="add_child()">
              Hinzufügen
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" v-model="editChild" :content-class="$vuetify.breakpoint.smAndDown ? '' : 'rounded-xl'">
      <v-card color="#CFD8DC" :class="$vuetify.breakpoint.smAndDown ? 'rounded-0' : 'rounded-xl'">
        <v-toolbar color="primary" dark>
          <v-toolbar-title>Kind hinzufügen</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="editChild = false">
            <font-awesome-icon icon="fa-light fa-xmark" size="lg" />
          </v-btn>
        </v-toolbar>
        <v-row class="pa-4">
          <v-col cols="12" md="6">
            <v-select :items="['Männlich', 'Weiblich', 'Keine Angabe']" hide-details="auto" rounded filled v-model="child.geschlecht" label="Geschlecht"></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field rounded filled hide-details="auto" v-model="child.vorname" label="Vorname"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field rounded filled hide-details="auto" v-model="child.nachname" label="Nachname"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field filled rounded hide-details="auto" label="Geburtsdatum" v-model="child.geburtsdatum" :rules="[rules.required, rules.geburtsdatum, custom_rules.alter]" />
          </v-col>
          <v-col cols="12" md="6">
            <v-select v-model="child.sportarten" :rules="[rules.required, custom_rules.sportarten]" hide-details="auto"  multiple :items="sports" item-value="name" item-text="name" label="Sportarten" rounded filled></v-select>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn rounded :disabled="!valid" x-large color="success" @click="save_child()">
              Speichern
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import logout from '@/auth/auth'
import {
  doc,
  updateDoc
} from 'firebase/firestore'


export default {
  name: 'MeineKinder',
  data(){
    return {
      addChild: false,
      editChild: false,
      editIndex: '',
      child_vorlage: {
        vorname: '',
        nachname: '',
        geburtsdatum: '',
        geschlecht: '',
        sportarten: []
      },
      child: {
        vorname: '',
        nachname: '',
        geburtsdatum: '',
        geschlecht: '',
        sportarten: []
      },
      custom_rules: {
        equals: (v) => v && this.eltern.email && v == this.eltern.email ? true : 'Die E-Mail Adressen stimmen nicht überein',
        equals_password: (v) => v && this.eltern.password && v == this.eltern.password ? true : 'Die Passwörter stimmen nicht überein',
        alter: (v) => v && v.split('.').length == 3 && v.split('.')[2] >= 2004 ? true : 'Dein Kind scheint zu alt für die Sportangebote zu sein.',
        sportarten: (v) => v && v.length >= 3 ? true : 'Bitte wähle mindestens 3 Sportarten aus.'
      },
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      rules: 'rules',
      vereine: 'vereine',
      db: 'db',
      sportarten: 'sportarten',
      sportangebote: 'sportangebote',
      anmeldungen: 'anmeldungen'
    }),
    sports() {
      var arr = this.sportarten.all
      arr.sort((a, b) => {
        if (a.name > b.name) {
          return 1
        } else {
          return -1
        }
      })

      return arr
    },
    valid(){
      if(this.child.vorname && this.child.nachname && this.child.geburtsdatum && this.child.geschlecht && this.child.sportarten?.length > 2){
        return true
      }
      return false
    }
  },
  mounted(){
    console.log(this.user.data)
    console.log(this.anmeldungen)
  },
  methods: {
    logout() {
      logout.logout(this.auth)
    },
    edit(index){
      this.child = Object.assign({}, this.user.data.kinder[index])
      this.editIndex = index
      this.editChild = true
    },
    save_child(){
      this.user.data.kinder[this.editIndex] = Object.assign({}, this.child)
      this.editChild = false
      this.editIndex = ''
      this.save()
    },
    add_child(){
      this.user.data.kinder.push(this.child)
      this.child = Object.assign({}, this.child_vorlage)
      this.addChild = false
      this.save()
    },
    async save(){
      const userdataRef = doc(this.db, "User", this.user.uid);
      await updateDoc(userdataRef, {
        kinder: this.user.data.kinder,
        sportarten: this.user.data.sportarten,
      });
    }
  },
}
</script>
